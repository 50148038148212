<svg
  id="svg"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  class="h-7 w-7"
  viewBox="0, 0, 400,400"
  fill="currentColor"
  ><g id="svgg"
    ><path
      id="path0"
      d="M68.750 200.789 L 68.750 398.765 167.266 398.657 C 238.777 398.579,266.852 398.450,269.688 398.187 C 306.380 394.782,327.420 388.297,343.678 375.380 C 347.936 371.998,354.282 365.160,356.503 361.563 C 356.769 361.133,357.946 359.349,359.120 357.598 C 360.294 355.847,361.352 354.160,361.471 353.849 C 361.591 353.538,362.499 351.919,363.489 350.252 C 365.814 346.336,369.935 337.568,371.406 333.410 C 373.954 326.204,375.283 322.109,375.631 320.396 C 375.832 319.405,376.335 317.108,376.748 315.292 C 382.481 290.101,380.574 262.015,371.778 242.098 C 366.845 230.925,358.046 219.327,349.688 212.978 C 348.570 212.130,347.586 211.340,347.500 211.223 C 347.215 210.837,341.453 207.030,338.426 205.229 C 331.348 201.018,317.826 195.485,310.391 193.757 C 308.384 193.290,308.487 193.056,311.007 192.351 C 319.851 189.878,330.987 183.994,338.906 177.611 C 368.179 154.014,378.612 106.658,363.129 67.656 C 362.310 65.594,361.401 63.273,361.109 62.500 C 360.817 61.727,359.783 59.617,358.810 57.813 C 357.838 56.008,356.806 54.015,356.516 53.383 C 356.226 52.752,355.162 50.994,354.150 49.477 C 353.138 47.960,352.230 46.508,352.132 46.250 C 352.033 45.992,351.060 44.586,349.969 43.125 C 348.878 41.664,347.646 39.977,347.231 39.375 C 335.361 22.150,313.122 9.565,286.784 5.168 C 272.545 2.791,273.843 2.816,165.234 2.814 L 68.750 2.813 68.750 200.789 M271.875 231.875 L 271.875 297.813 285.469 297.813 L 299.063 297.813 299.063 320.781 L 299.063 343.750 285.469 343.750 L 271.875 343.750 271.875 363.906 L 271.875 384.063 246.094 384.063 L 220.313 384.063 220.313 363.906 L 220.313 343.750 165.938 343.750 L 111.563 343.750 111.563 319.400 L 111.563 295.050 165.859 230.498 L 220.156 165.946 246.016 165.942 L 271.875 165.938 271.875 231.875 M215.749 235.000 C 213.325 237.836,200.343 253.094,186.900 268.906 L 162.457 297.656 191.279 297.736 C 207.131 297.780,220.149 297.768,220.208 297.709 C 220.267 297.650,220.280 282.356,220.236 263.723 L 220.156 229.844 215.749 235.000 "
      stroke="none"
      fill-rule="evenodd"
    /></g
  ></svg
>
